/*
 * @Author: weiqi
 * @Date: 2021-08-22 21:29:46
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-16 12:37:13
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/hooks/useStore.tsx
 */
import React from 'react'
import { useLocalStore } from 'mobx-react-lite'
import rootStore, { RootStore } from '../stores/index'

const storeContext = React.createContext<RootStore | null>(null)

export const StoreProvider = ({ children }: { children: React.ReactNode }) => {
  const store = useLocalStore(() => rootStore)
  return <storeContext.Provider value={store}>{children}</storeContext.Provider>
}

export const useStore = () => {
  const store = React.useContext(storeContext)
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('useStore must be used within a StoreProvider.')
  }
  return store
}
