/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-16 23:58:09
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/apis/system.ts
 */
import { request } from '../services/request'
import { authPath } from '../services/config'

export default class SystemRequest {
  // 获取系统任务列表
  static get_sys_cronjobs() {
    return request(authPath + 'auth/system_cronjobs', 'GET', {}, { showloading: true })
  }
  // 更新系统任务状态
  static put_sys_cronjob_state(data: any) {
    return request(authPath + 'auth/system_cronjob/scheduled', 'PUT', data)
  }
  // 获取自定义任务列表
  static get_customize_cronjobs() {
    return request(authPath + 'auth/customize_cronjobs', 'GET', {}, { showloading: true })
  }
  // 更新自定义任务状态
  static put_customize_cronjob_state(data: any) {
    return request(authPath + 'auth/customize_cronjob/scheduled', 'PUT', data)
  }
}
