/*
 * @Author: weiqi
 * @Date: 2021-11-20 13:40:13
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 13:55:30
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/employee/empolyeeStore.ts
 */
import { observable, action, makeObservable } from 'mobx'
import { MessageUtils } from '../../utils'
import Request from '../../apis/employee'
export class EmployeeStore {
  employee_list: any = {} //employee_detail
  constructor() {
    makeObservable(this, {
      add_employee: action,
      update_employee: action,
      employee_list: observable,
    })
  }

  // 添加员工
  async add_employee(data: any) {
    MessageUtils.MsgSuccess((await Request.post_employee(data)).data.message)
  }

  // 更新员工
  async update_employee(id: number, data: any) {
    MessageUtils.MsgSuccess((await Request.put_employee(id, data)).data.message)
  }

  // 获取员工信息
  async request_employee_detail(id: any) {
    const EmployeeInfo = (await Request.get_employee(id)).data.data
    this.employee_list = EmployeeInfo
    return { state: true, opt: EmployeeInfo }
  }

  // 更新员工信息
  async update_employee_info(id: number, data: any) {
    MessageUtils.MsgSuccess((await Request.update_profile_info(id, data)).data.message)
  }

  // 上传员工头像
  async add_employee_photo(data: any) {
    const addPhotoRes = await Request.post_profile_photo(data)
    MessageUtils.MsgSuccess(addPhotoRes.data.message)
    return { opt: addPhotoRes.data, state: true }
  }
}

export default new EmployeeStore()
