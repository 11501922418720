/*
 * @Author: weiqi
 * @Date: 2021-11-19 11:06:32
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-21 16:53:33
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/app.tsx
 */
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import loadable from './hooks/useLoadable'

import 'animate.css'
import './style/base.scss'
import './style/app.scss'
import Login from './pages/Login'
import zh_CN from 'antd/lib/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import UserCenter from './pages/UserCenter'
import { ConfigProvider } from 'antd'
moment.locale('zh-cn')

// 公共模块
const DefaultLayout = loadable(() => import(/* webpackChunkName: 'default' */ './containers/DefaultLayout'))
const App = () => (
  <ConfigProvider locale={zh_CN}>
    <Router>
      <Switch>
        <Route path='/' exact component={Login} />
        <Route path='/usercenter' component={UserCenter} />
        <Route component={DefaultLayout} />
      </Switch>
    </Router>
  </ConfigProvider>
)

export default App
