/*
 * @Author: weiqi
 * @Date: 2021-11-11 16:47:05
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-19 17:17:16
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/utils/utils.ts
 */
import moment from 'moment'
import _ from 'lodash'
class Utils {
  /**
   * 返回一个标准时间格式
   * @param format 时间格式
   * @returns date string
   */
  static createNewDate = (format: string) => {
    if (format) return moment().format(format)
  }

  /**
   * Utils.FormatFormData(query)(field)(field)
   * 表单数据规范化，将需要被过滤掉的字段
   * 1.''
   * 2.null
   * 统一赋值为undefined以便筛选
   * @param query 查询条件结构体
   */
  static FormatFormData(query: any) {
    function continuous(field: string) {
      if (query[field] === null || query[field] === '') query[field] = undefined
      return continuous
    }

    return continuous
  }

  /**
   * 格式化查询条件,支持链式调用
   * Utils.FormatRequestQuery(query)(field,type)(field,type)
   * 当field值为undefined时过滤掉该字段，不作为查询条件传给服务端
   * @param {any} query 查询条件结构体
   * @param {String} field 查询字段
   * @param {String} type 查询类型(like,between等)
   * @returns
   */
  static FormatRequestQuery = (query: any) => {
    function continuous(field: string, type: string, multicondion?: { key: string; type: string }[]) {
      if (query[field] === undefined) {
        delete query[field]
      } else {
        switch (field) {
          case 'opor':
          case 'opand':
            const thisvalue = query[field]
            query[field] = _.map(multicondion, item => {
              return { [item.key]: { type: item.type, value: thisvalue } }
            })
            break
          default:
            query[field] = {
              value: query[field],
              type: type,
            }
            break
        }
      }

      return continuous
    }

    return continuous
  }
}

export default Utils
