/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 14:45:23
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/home/homeStore.ts
 */
import { observable, makeObservable } from 'mobx'
import Request from '../../apis/home'

export class HomeStore {
  statistics: any
  constructor() {
    makeObservable(this, {
      statistics: observable,
    })
  }

  // 获取统计信息
  async get_statistics() {
    this.statistics = (await Request.get_statistics()).data.data
  }
}

export default new HomeStore()
