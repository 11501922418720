/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-22 18:57:20
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/product/statistics.ts
 */
import { makeObservable, observable, toJS } from 'mobx'
import { ProductStatisticRequest } from '../../apis'
import { ProductStatisticListType, StatisticOverviewType, TrendChartListType } from '../../interfaces/business'
import _ from 'lodash'
import moment from 'moment'
export class ProductStatisticsStore {
  product_id = 0
  product_title = ''
  offering_size = 0
  total_quantity = 0
  startAt = '' //第一笔资产录入时间
  endAt = '' //最后一笔资产录入时间
  product_statistic_list: ProductStatisticListType = { count: 0, rows: [] }
  trend_chart_list: TrendChartListType = { count: 0, rows: [] }

  constructor() {
    makeObservable(this, {
      product_statistic_list: observable,
      product_id: observable,
      trend_chart_list: observable,
    })
  }

  /**
   * 获取产品进度募资数据
   * @param product_id
   * @param query
   */
  async request_product_statistics(product_id: number, query = {}) {
    this.product_statistic_list = (await ProductStatisticRequest.request_product_statistics(product_id, query)).data.data as ProductStatisticListType
  }

  /**
   * 获取员工募资进度详情
   * @param product_id
   * @param created_by
   * @returns
   */
  async request_employee_work_progress_record(product_id: number, created_by: number, query = {}) {
    return (await ProductStatisticRequest.request_employee_work_statistics(product_id, created_by, query)).data.data as ProductStatisticListType
  }

  async request_statistics_overview(product_id: number) {
    const statisticOverview: StatisticOverviewType = (await ProductStatisticRequest.request_statistics_overview(product_id)).data.data
    _.each(statisticOverview, (value, key) => {
      this[key as keyof typeof statisticOverview] = value as never
    })
  }

  async request_product_progress_trend(product_id: number) {
    this.trend_chart_list = (await ProductStatisticRequest.request_product_progress_trend(product_id)).data.data
  }

  get_statistic_list() {
    return toJS(this.product_statistic_list)
  }

  get_trend_chart_list(date_range: any[]) {
    const replicaList = Object.assign({}, this.trend_chart_list)
    if (date_range) {
      replicaList.rows = _.filter(this.trend_chart_list.rows, item => {
        return moment(item.data_time) >= date_range[0] && moment(item.data_time) <= date_range[1]
      })
    }
    return toJS(replicaList)
  }

  getOverview() {
    return toJS({
      product_id: this.product_id,
      product_title: this.product_title,
      offering_size: this.offering_size,
      total_quantity: this.total_quantity,
      startAt: this.startAt,
      endAt: this.endAt,
    })
  }
}
