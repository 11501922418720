/*
 * @Author: weiqi
 * @Date: 2021-11-20 13:40:13
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-22 12:51:56
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/customer/customerListStore.ts
 */
import { observable, action, makeObservable, toJS } from 'mobx'
import Request from '../../apis/customer'
import { Utils } from '../../utils'
import { CustomerQuickViewListType } from '../../interfaces/business'

export class CustomerListStore {
  customer_list: any = { count: 0, rows: [] }
  total = 0
  sort: any = ''
  constructor() {
    makeObservable(this, {
      customer_list: observable,
      get_customer_list: action,
      setSort: action,
    })
  }

  // 获取会员列表
  get_customer_list(page: number, count: number, query?: any) {
    Request.get_customer_list(page, count, query, this.sort).then((result: { data: { message: string; data: any } }) => {
      this.customer_list = result.data.data
    })
  }

  async request_owner_customer_list(page: number, count: number, query = {}): Promise<CustomerQuickViewListType> {
    const formatedQuery = { ...query }
    Utils.FormatFormData(formatedQuery)('name')
    Utils.FormatRequestQuery(formatedQuery)('name', 'like')
    return (await Request.request_manager_owners_customers(page, count, formatedQuery)).data.data
  }

  pagination(page: number, count: number, query = {}) {
    const formatedQuery = { ...query }
    // console.log(formatedQuery)
    Utils.FormatFormData(formatedQuery)('opor')('class')('manage')('is_active')('amount_count')
    Utils.FormatRequestQuery(formatedQuery)('opor', '', [
      { key: 'name', type: 'like' },
      { key: 'phone', type: 'like' },
      { key: 'ssn', type: 'like' },
    ])('amount_count', 'between')
    this.get_customer_list(page, count, formatedQuery)
  }

  setSort(sort: any) {
    this.sort = sort
  }

  get_customer_list_state() {
    return toJS(this.customer_list)
  }
}

export default new CustomerListStore()
