/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 17:03:23
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/apis/company.ts
 */
import { request } from '../services/request'
import { authPath } from '../services/config'

export default class CompanyRequest {
  // 添加公司
  static post_company(data: any) {
    return request(authPath + 'auth/company', 'POST', data, { throwErr: true })
  }
  // 更新公司信息
  static put_company(id: any, data: any) {
    return request(authPath + `auth/company/${id}`, 'PUT', data, { throwErr: true })
  }

  // 获取公司列表
  static get_companys(page: number, count: number, query = {}) {
    return request(authPath + `auth/company?query=${JSON.stringify(query)}&page=${page}&count=${count}`, 'GET', {}, { showloading: true })
  }

  //删除公司
  static del_company(id: any) {
    return request(authPath + `auth/company/${id}`, 'DELETE', {})
  }

  //获取公司详情
  static get_company_detail(id: any) {
    return request(authPath + `auth/company/${id}`, 'GET', {}, { showloading: true })
  }
}
