import { observable, action, makeObservable, toJS } from 'mobx'
import { MessageUtils, Excel, ObjectUtils, Utils } from '../../utils'
import { inputsValidator } from '../../utils/validator'
import { base } from '../../config/index'
import Request from '../../apis/assets'
import _ from 'lodash'
export class AssetListStore {
  asset_list: any = { count: 0, rows: [] }
  product_asset_list: any = { count: 0, rows: [] }
  product_id: any
  formal_excel_header = {
    contract_no: '协议编号',
    name: '姓名',
    ssn: '身份证号码/机构编码',
    phone: '手机号码',
    amount: '金额',
    interest: '对应利率',
    transferin_date: '转入日期',
    interest_start_date: '起息日期',
    bank: '银行',
    bank_card: '银行卡号',
    location: '客户所在区域',
  }

  constructor() {
    makeObservable(this, {
      product_id: observable,
      asset_list: observable,
      product_asset_list: observable,
      post_excel_file: action,
      request_asset_list: action,
    })
  }

  is_sheet_valid(excel_json: any, productId: any, period: string) {
    return new Promise((resolve, reject) => {
      if (!_.isInteger(parseInt(period))) {
        MessageUtils.MsgWarning(`月份【${period}】错误，请按正确格式输入月份`)
        reject(`月份${period}错误`)
      }
      //检查表头
      _.each(excel_json[0], (v, k) => {
        if (!_.includes(this.formal_excel_header, k)) {
          MessageUtils.MsgWarning(`表头 [${k}] 格式错误`)
          reject(`表头 [${k}] 格式错误`)
        }
      })

      for (let i = 0; i < excel_json.length; i++) {
        const record = excel_json[i]
        try {
          inputsValidator.ObjectElementType(record, i + 1, period)('协议编号', 'String')('姓名', 'String')('身份证号码/机构编码', 'String')('手机号码', 'String')('金额', 'Number')(
            '对应利率',
            'Number'
          )('转入日期', 'Any')('起息日期', 'Any')('银行', 'String')('银行卡号', 'String')
          record.product_id = productId
          record.period = period
          record['金额'] = record['金额'] * base.TThousand
        } catch (e: any) {
          MessageUtils.MsgWarning(e)
          reject(e)
        }
      }

      resolve(excel_json)
    })
  }

  // 上传Excel文件
  async post_excel_file(data: any) {
    MessageUtils.MsgSuccess((await Request.upload_file(data)).data.message)
  }

  set_product_id(product_id: any) {
    this.product_id = product_id
  }

  //导出资产明细excel文档
  async export_excel_file(query?: any) {
    let formatedQuery = { ...query }

    Utils.FormatFormData(formatedQuery)('opor')('contract_no')('createdAt')('state')('created_by')('amount')
    Utils.FormatRequestQuery(formatedQuery)('opor', '', [
      { key: 'name', type: 'like' },
      { key: 'phone', type: 'like' },
      { key: 'ssn', type: 'like' },
    ])('contract_no', 'like')('createdAt', 'between')('created_by', 'eq')('state', 'eq')('amount', 'between')
    formatedQuery = { ...formatedQuery, product_id: this.product_id }

    const exportAssetsList = (await Request.get_assets(1, Number.MAX_SAFE_INTEGER, formatedQuery)).data.data
    if (exportAssetsList.count === 0) throw '待导出表单为空'
    const format_excel: any = {}
    _.each(exportAssetsList.rows, (row: any) => {
      if (!format_excel[row['period']]) format_excel[row['period']] = []
      format_excel[row['period']].push(ObjectUtils.replaceKey(row, this.formal_excel_header))
    })
    Excel.DownloadExcel(format_excel)
  }

  //删除资产记录
  async del_asset(id: string) {
    MessageUtils.MsgSuccess((await Request.del_asset(id)).data.message)
  }

  //清空产品资产明细表
  async empty_assets(id: number) {
    MessageUtils.MsgSuccess((await Request.empty_assets(id)).data.message)
  }

  // 获取资产明细列表
  async request_asset_list(page: number, count: number, query = {}) {
    this.asset_list = (await Request.get_assets(page, count, query)).data.data
  }

  // 获取资产明细列表(含产品),用于查看客户资产
  async request_product_asset_list(page: number, count: number, query = {}) {
    this.product_asset_list = (await Request.get_assets_product(page, count, query)).data.data
  }

  pagination(page: number, count: number, query = {}) {
    let formatedQuery = { ...query }
    Utils.FormatFormData(formatedQuery)('opor')('contract_no')('createdAt')('state')('created_by')('amount')
    Utils.FormatRequestQuery(formatedQuery)('opor', '', [
      { key: 'name', type: 'like' },
      { key: 'phone', type: 'like' },
      { key: 'ssn', type: 'like' },
    ])('contract_no', 'like')('createdAt', 'between')('created_by', 'eq')('state', 'eq')('amount', 'between')
    formatedQuery = { ...formatedQuery, product_id: this.product_id }
    this.request_asset_list(page, count, formatedQuery)
  }

  product_pagination(page: number, count: number, query?: any) {
    let formatedQuery = { ...query }
    formatedQuery = { ...formatedQuery }
    this.request_product_asset_list(page, count, formatedQuery)
  }

  get_product_asset_list() {
    return toJS(this.product_asset_list)
  }

  get__assets_list() {
    return toJS(this.asset_list)
  }
}

export default new AssetListStore()
