/*
 * @Author: weiqi
 * @Date: 2021-11-20 13:40:13
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 11:25:58
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/utils/monitor.ts
 */
/// 生成时间戳
function datatime() {
  const d = new Date()
  const time = d.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, '$1')
  const day = d.toJSON().slice(1, 10)
  return `${day} ${time}`
}

const processErrorInfo = (info: any) => {
  let col, line, filename, message, stack
  let type = 'error'
  if (info instanceof ErrorEvent) {
    col = info.colno
    line = info.lineno
    filename = info.filename
    message = info.message
    stack = info.error.stack
    type = info.type
  } else if (info instanceof Error) {
    message = info.message
    stack = info.stack
  }

  const userInfo = {
    user_id: '12138',
    user_name: '执念',
  }

  return {
    ...userInfo,
    col,
    line,
    filename,
    message,
    stack,
    type,
    timetamp: datatime(),
  }
}

export default processErrorInfo
