/*
 * @Author: weiqi
 * @Date: 2021-08-22 21:29:46
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 11:39:54
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/hooks/useLoadable.tsx
 */
import { useEffect } from 'react'
import Loadable from 'react-loadable'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const useLoadingComponent = () => {
  useEffect(() => {
    NProgress.start()
    return () => {
      NProgress.done()
    }
  }, [])

  return <div />
}

export default (loader: any, loading = useLoadingComponent) => {
  return Loadable({ loader, loading })
}
