/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-18 00:13:39
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/event/eventListStore.ts
 */
import { observable, action, makeObservable } from 'mobx'
import { MessageUtils } from '../../utils'
import { Utils } from '../../utils'
import Request from '../../apis/event'
export class EventListStore {
  event_list: any = {}
  constructor() {
    makeObservable(this, {
      event_list: observable,
      get_event_list: action,
    })
  }

  async del_event(id: string) {
    MessageUtils.MsgSuccess((await Request.del_event(id)).data.message)
  }

  // 获取产品列表
  async get_event_list(page: number, count: number, query = {}) {
    this.event_list = (await Request.get_events(page, count, query)).data.data
  }

  pagination(page: number, count: number, query?: any) {
    const formatedQuery = { ...query }
    Utils.FormatFormData(formatedQuery)('event_title')('start_time')('state')
    Utils.FormatRequestQuery(formatedQuery)('event_title', 'like')('start_time', 'between')('state', 'eq')
    this.get_event_list(page, count, formatedQuery)
  }
}

export default new EventListStore()
