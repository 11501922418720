/*
 * @Author: weiqi
 * @Date: 2021-09-18 10:23:30
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-14 18:00:01
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/loading/loading.ts
 */
import { observable, action, makeObservable } from 'mobx'
export class LoadingStore {
  loading = false
  drawer_visible = false
  constructor() {
    makeObservable(this, {
      loading: observable,
      set_loading: action,
      remove_loading: action,
    })
  }

  set_loading() {
    this.loading = true
  }

  remove_loading() {
    this.loading = false
  }

  set_drawer(b: boolean) {
    this.drawer_visible = b
  }
}

export default new LoadingStore()
