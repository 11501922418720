/*
 * @Author: weiqi
 * @Date: 2022-03-17 16:10:26
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 17:23:39
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/apis/news.ts
 */
import { request } from '../services/request'
import { authPath } from '../services/config'

export default class NewsRequest {
  // 添加资讯
  static post_news(data: any) {
    return request(authPath + 'auth/news', 'POST', data, { showloading: true })
  }
  // 更新资讯
  static put_news(id: any, data: any) {
    return request(authPath + `auth/news/${id}`, 'PUT', data, { showloading: true })
  }

  // 更新资讯发布状态
  static put_news_publish(id: any, data: any) {
    return request(authPath + `auth/news/publish/${id}`, 'PUT', data, { showloading: true })
  }

  //添加资讯封面图片
  static post_news_cover_image(data: any) {
    return request(authPath + 'auth/news/cover_upload', 'POST', data)
  }

  //添加资讯内容图片
  static post_news_content_image(data: any) {
    return request(authPath + 'auth/content/news_upload', 'POST', data)
  }

  // 获取资讯列表
  static get_news_list(page: number, count: number, query = {}) {
    return request(authPath + `auth/news?query=${JSON.stringify(query)}&page=${page}&count=${count}`, 'GET', {}, { showloading: true })
  }
  //删除资讯
  static del_news(id: any) {
    return request(authPath + `auth/news/${id}`, 'DELETE', {}, { throwErr: true })
  }
  //获取资讯详情
  static request_news_detail(id: any) {
    return request(authPath + `auth/news/${id}`, 'GET', {}, { showloading: true })
  }
}
