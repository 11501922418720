/*
 * @Author: weiqi
 * @Date: 2021-11-23 12:05:08
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 14:39:01
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/company/companyListStore.ts
 */
import { observable, action, makeObservable } from 'mobx'
import { MessageUtils } from '../../utils'
import Request from '../../apis/company'
// import _ from 'lodash'
import { Utils } from '../../utils'

export class CompanyListStore {
  company_list: any = { rows: [], count: '' }

  constructor() {
    makeObservable(this, {
      company_list: observable,
      get_company_list: action,
    })
  }

  //删除公司
  async del_company(id: string) {
    MessageUtils.MsgSuccess((await Request.del_company(id)).data.message)
  }

  // 获取公司列表
  get_company_list(page: number, count: number, query = {}) {
    Request.get_companys(page, count, query).then((result: { data: { message: string; data: any } }) => {
      this.company_list = result.data.data
    })
  }

  pagination(page: number, count: number, query = {}) {
    const formatedQuery = { ...query }
    Utils.FormatFormData(formatedQuery)('company_name')
    Utils.FormatRequestQuery(formatedQuery)('company_name', 'like')
    this.get_company_list(page, count, formatedQuery)
  }
}

export default new CompanyListStore()
