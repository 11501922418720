/*
 * @Author: weiqi
 * @Date: 2021-11-16 15:07:43
 * @LastEditors: weiqi
 * @LastEditTime: 2022-04-30 19:15:38
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/config.ts
 */
export default {
  dataServer: {
    // host: "192.168.3.162",
    host: '127.0.0.1',
    // host: 'cms-server.bbimp.com',
    // host: '119.45.169.63',
    port: '3001',
  },
  dataServerDev: {
    host: '127.0.0.1',
    // host: '47.92.94.8',
    port: '3001',
  },
  dataServerDocker: {
    // host: '127.0.0.1', //serverHost
    // host: '119.45.169.63',
    // host: 'cms-server.bbimp.com',
    host: 'cs.bzytz.com',
    port: '',
  },
  apiModulePath: {
    dataSettingsPath: '/api/v0.1.0/auth/',
    authPath: '/api/v0.1.0/',
  },
  dataApi: {
    app: '/',
  },
}
