/*
 * @Author: weiqi
 * @Date: 2021-11-25 17:12:08
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-16 23:42:38
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/pages/UserCenter/edit_user_pwd/index.tsx
 */
import { useEffect, useState } from 'react'
import { Form, Input, Modal, message, Button } from 'antd'
import { useStore } from '../../../hooks/useStore'
import { observer } from 'mobx-react-lite'

const formLayout: any = {
  labelCol: { span: 4 },
}
export const EditUserPwd = observer((props: any) => {
  const { editPwdVisible, setEditPwdVisible } = props
  const { profileInstance } = useStore()
  const [loading, setLoading] = useState<boolean>(false)
  const [pwdForm] = Form.useForm()

  useEffect(() => {
    pwdForm.resetFields()
  }, [])

  // 保存
  const save_comfirm = async () => {
    try {
      setLoading(true)
      const values = await pwdForm.validateFields()
      if (values.password !== values.new_password) {
        message.warn('两次输入新密码不一致，请重新输入!')
        setLoading(false)
        return
      }
      const data = {
        old_password: values.old_password,
        new_password: values.new_password,
      }
      await profileInstance.post_user_pwd(data)
      setEditPwdVisible(false)
      // 修改成功后，跳转登录页面
      localStorage.clear()
      props.history.push('/')
    } catch (e: any) {
      setLoading(false)
    }
  }
  return (
    <Modal
      maskClosable={false}
      title='修改密码'
      visible={editPwdVisible}
      footer={[
        <Button
          onClick={() => {
            setEditPwdVisible(false)
          }}
        >
          取消
        </Button>,
        <Button onClick={save_comfirm} type='primary' loading={loading}>
          确认
        </Button>,
      ]}
    >
      <Form form={pwdForm} {...formLayout}>
        <Form.Item label='旧密码' name='old_password' rules={[{ required: true }]}>
          <Input type='password' placeholder='请输入旧密码' />
        </Form.Item>
        <Form.Item label='新密码' name='password' rules={[{ required: true }]}>
          <Input type='password' placeholder='请输入新密码' />
        </Form.Item>
        <Form.Item label='确认密码' name='new_password' rules={[{ required: true }]}>
          <Input type='password' placeholder='请再次输入新密码' />
        </Form.Item>
      </Form>
    </Modal>
  )
})
