/*
 * @Author: weiqi
 * @Date: 2022-03-16 11:23:56
 * @LastEditors: weiqi
 * @LastEditTime: 2022-07-03 17:11:03
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/utils/validator/inputs.ts
 */
import validator from 'validator'

export const inputsValidator = {
  // 是否仅仅包含数字
  isNumeric: (card: string) => {
    // if (!validator.isNumeric(card)) throw '银行卡号格式错误:只能由数字组成';
    const pattern = /^[0-9]+$/i
    if (!pattern.test(card)) throw '银行卡号格式错误:只能由数字组成'
  },

  // 检查字符串是否是手机号码
  isMobilePhone: (phone: string) => {
    if (!validator.isMobilePhone(phone, 'zh-CN')) throw '手机号码格式错误,不支持非大陆手机号'
  },

  // 检查字符串是否是电子邮件
  isEmail: (email: string) => {
    if (!validator.isEmail(email)) throw '邮箱格式错误'
  },
  // 检查字符串是否是有效的身份证代码
  isIdentityCard: (str: string) => {
    if (str.length !== 18 || !validator.isIdentityCard(str, 'any')) throw '身份证格式错误'
  },
  ObjectElementType(record: { [propeName: string]: any }, idx: number, period: string) {
    function continuous(key: string, type: 'String' | 'Number' | 'Any') {
      const value = record[key]
      const typeText = type == 'String' ? '文本' : '数字/日期'
      if (!value) throw `${period}月投资期限,第${idx}行，${key}不能为空`
      const valueType = type == 'Any' ? '[object Any]' : Object.prototype.toString.call(value)
      if (valueType != `[object ${type}]`) throw `${period}月投资期限,第${idx}行，[${key}:${value}]应该为[${typeText}]类型,请检查单元格格式`
      return continuous
    }
    return continuous
  },

  //   isType(key: string, value: any, type: 'String' | 'Number') {

  //   },
}
