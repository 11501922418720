/* eslint-disable prettier/prettier */
/*
 * @Author: weiqi
 * @Date: 2021-08-22 21:29:46
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 11:40:37
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/reportWebVitals.js
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const reportWebVitals = (onPerfEntry) => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        import ('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
            getCLS(onPerfEntry)
            getFID(onPerfEntry)
            getFCP(onPerfEntry)
            getLCP(onPerfEntry)
            getTTFB(onPerfEntry)
        })
    }
}

export default reportWebVitals