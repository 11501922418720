/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 17:27:26
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/apis/profile.ts
 */
import { request } from '../services/request'
import { authPath } from '../services/config'

export default class ProfileRequest {
  static request_profile() {
    return request(authPath + 'auth/usercenter', 'GET', {})
  }
  static put_profile(data: any) {
    return request(authPath + 'auth/usercenter', 'PUT', data, { throwErr: true })
  }
  static put_user_pwd(data: any) {
    return request(authPath + 'auth/change_password', 'PUT', data, { throwErr: true })
  }
}
