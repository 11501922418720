/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 17:26:17
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/profile/profileStore.ts
 */
import { makeObservable, observable, toJS } from 'mobx'
import ProfileRequest from '../../apis/profile'
import { MessageUtils } from '../../utils'
import { UserProfileType } from '../../interfaces/business'
export class ProfileStore {
  profile_data: UserProfileType = {
    employee_id: 0,
    sys_auth_id: 0,
    sys_auth_type: '',
    username: '',
    is_active: 1,
    employee_profile_id: 0,
    avatar_url: '',
    wechat_id: '',
    phone: '',
    nickname: '',
    gender: 1,
    email: '',
    is_show: 0,
    createdAt: '',
    updatedAt: '',
  }
  constructor() {
    makeObservable(this, {
      profile_data: observable,
    })
  }

  // 获取个人详情
  async request_userprofile() {
    const profileInfo = (await ProfileRequest.request_profile()).data.data
    this.profile_data = profileInfo
  }

  // 更新个人信息
  async update_information(data: any) {
    MessageUtils.MsgSuccess((await ProfileRequest.put_profile(data)).data.message)
  }

  // 修改密码
  async post_user_pwd(data: any) {
    MessageUtils.MsgSuccess((await ProfileRequest.put_user_pwd(data)).data.message)
  }

  get_profile(): UserProfileType {
    return toJS(this.profile_data)
  }
}
