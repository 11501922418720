/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-21 12:35:37
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/news/newsStore.ts
 */
import { action, makeObservable, observable } from 'mobx'
import { MessageUtils } from '../../utils'
import Request from '../../apis/news'

export class NewsStore {
  new_detail: any = {}
  constructor() {
    makeObservable(this, {
      add_news: action,
      update_news: action,
      add_news_cover_image: action,
      new_detail: observable,
    })
  }

  // 添加产品
  async add_news(data: any) {
    MessageUtils.MsgSuccess((await Request.post_news(data)).data.message)
  }

  // 添加资讯封面图片
  async add_news_cover_image(data: any) {
    const postNewCoverImgRes = await Request.post_news_cover_image(data)
    MessageUtils.MsgSuccess(postNewCoverImgRes.data.message)
    return { opt: postNewCoverImgRes.data, state: true }
  }

  // 添加内容图片
  async upload_content_image(data: any) {
    const postNewContentImgRes = await Request.post_news_content_image(data)
    MessageUtils.MsgSuccess(postNewContentImgRes.data.message)
    return { opt: postNewContentImgRes.data, state: true }
  }

  // 获取资讯详情
  async request_news_detail(id: any) {
    const NewsDetail = (await Request.request_news_detail(id)).data.data
    this.new_detail = NewsDetail
    return NewsDetail
  }

  // 更新资讯详情
  async update_news(id: any, data: any) {
    MessageUtils.MsgSuccess((await Request.put_news(id, data)).data.message)
  }

  // 更新资讯发布状态
  async update_news_publish(id: any, data: any) {
    MessageUtils.MsgSuccess((await Request.put_news_publish(id, data)).data.message)
  }
}

export default new NewsStore()
