/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 14:32:32
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/banner/bannerStore.ts
 */
import { action, makeObservable, observable } from 'mobx'
import { MessageUtils } from '../../utils'
import Request from '../../apis/banner'
import _ from 'lodash'

export class BannerStore {
  banner_list: any = []

  constructor() {
    makeObservable(this, {
      add_banner: action,
      update_banner: action,
      banner_list: observable,
    })
  }

  // 添加轮播图图片 - 上传COS
  async add_banner_cover_image(data: any) {
    const addBannerImgRes = await Request.post_banner_cover_image(data)
    MessageUtils.MsgSuccess(addBannerImgRes.data.message)
    return { opt: addBannerImgRes.data, state: true }
  }

  // 添加轮播图
  async add_banner(data: any) {
    MessageUtils.MsgSuccess((await Request.post_banner(data)).data.message)
  }

  // 获取轮播图详情
  async get_banner_detail(id: any) {
    const BannerDetail = (await Request.get_banner_detail(id)).data.data
    const list = _.map(BannerDetail, function (item, i) {
      return { id: i, img: item }
    })
    return list
  }

  // 更新轮播图
  async update_banner(id: any, data: any) {
    MessageUtils.MsgSuccess((await Request.put_banner(id, data)).data.message)
  }

  // 删除轮播图
  async delete_banner(id: any, data: any) {
    MessageUtils.MsgSuccess((await Request.delete_banner(id, data)).data.message)
  }
}

export default new BannerStore()
