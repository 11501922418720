import { useState, useEffect } from 'react'
import { Modal, Form, Input, Select, Upload, Button } from 'antd'
import { base } from '../../../config/index'
import { useStore } from '../../../hooks/useStore'
import { PlusOutlined } from '@ant-design/icons'
import { validatorUtils } from '../../../utils/validator/validator'

const formLayout: any = {
  labelCol: { span: 4 },
}
const { Option }: any = Select
export const EditUserProfile = (props: any) => {
  const { editProfileVisible, setEditProfileVisible } = props
  const [filelist, setFileList] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { profileInstance, employeeStoreInstance } = useStore()
  const { nickname, avatar_url, phone, wechat_id, gender, email } = profileInstance.get_profile()
  const [infoForm] = Form.useForm()

  useEffect(() => {
    if (editProfileVisible) {
      setFileList([{ url: avatar_url }])
      //   infoForm.setFieldsValue(toJS(profileInstance.profile_data))
    }
  }, [editProfileVisible])

  const validator = async (rule: any, value: any) => {
    await validatorUtils(rule, value)
  }

  // 保存
  const save_comfirm = async () => {
    try {
      setLoading(true)
      const values = await infoForm.validateFields()
      delete values.avatar_url
      values['avatar_url'] = filelist ? filelist[0].url : null
      await profileInstance.update_information(values)
      setEditProfileVisible(false)
      await profileInstance.request_userprofile()
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  // 上传头像
  const handleUpload = (option: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(option.file)
    reader.onloadend = async function (e: any) {
      const data = {
        data: e.target.result,
      }
      const result: any = await employeeStoreInstance.add_employee_photo(data)

      infoForm.setFieldsValue({ img: result.opt.data.data })
      setFileList([{ url: result.opt.data.data }])
      if (e && e.target && e.target.result) {
        option.onSuccess()
      }
    }
  }

  // 删除头像
  const removeImg = () => {
    setFileList([])
  }

  return (
    <Modal
      maskClosable={false}
      title='编辑员工信息'
      visible={editProfileVisible}
      onCancel={() => {
        setEditProfileVisible(false)
      }}
      footer={[
        <Button
          onClick={() => {
            setEditProfileVisible(false)
          }}
        >
          取消
        </Button>,
        <Button onClick={save_comfirm} type='primary' loading={loading}>
          确认
        </Button>,
      ]}
    >
      <Form
        {...formLayout}
        form={infoForm}
        initialValues={{
          nickname: nickname,
          gender: gender,
          wechat_id: wechat_id,
          email: email,
          phone: phone,
          avatar_url: avatar_url,
        }}
      >
        <Form.Item label='姓名' name='nickname' rules={[{ message: '请输入姓名', required: true, validator: validator }]}>
          <Input placeholder='请输入姓名' />
        </Form.Item>
        <Form.Item label='姓别' name='gender'>
          <Select placeholder='请选择性别'>
            {base.gender.map((item, index) => (
              <Option value={index}>{item}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label='微信号' name='wechat_id' rules={[{ message: '请输入微信号', required: true, validator: validator }]}>
          <Input placeholder='请输入微信号' />
        </Form.Item>
        <Form.Item label='邮箱地址' name='email' rules={[{ message: '请输入邮箱地址', required: true, validator: validator }]}>
          <Input placeholder='请输入邮箱地址' />
        </Form.Item>
        <Form.Item label='手机号' name='phone' rules={[{ message: '请输入手机号码', required: true, validator: validator }]}>
          <Input maxLength={11} placeholder='请输入手机号码' />
        </Form.Item>
        <Form.Item name='avatar_url' label='用户头像'>
          <Upload
            type='select'
            listType='picture-card'
            fileList={filelist}
            onRemove={removeImg}
            showUploadList={{
              showRemoveIcon: true,
              showPreviewIcon: false,
            }}
            maxCount={1}
            customRequest={handleUpload}
          >
            <PlusOutlined />
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  )
}
