export default {
  methodType: {
    get: '#87d068',
    delete: '#f50',
    put: '#1890ff',
    post: '#a3c4ea',
    _all: '#108ee9',
  },
  pagingConfig: {
    page: 1,
    count: 10,
  },
  productState: ['未发布', '已发布', '已结束'],
  productStateColor: ['default', 'success', 'error'],
  eventState: ['未发布', '已发布'],
  eventStateColor: ['default', 'success'],
  newsState: ['未发布', '已发布'],
  newsStateColor: ['default', 'success'],
  userActive: ['封禁', '正常'],
  userActiveeColor: ['error', 'success'],
  employeeActive: ['封禁', '正常'],
  employeeActiveeColor: ['error', 'success'],
  employeeIsShow: ['不展示', '展示'],
  employeeIsShowColor: ['error', 'success'],
  assetState: ['持有', '上架', '已预约'],
  gender: ['未知', '男', '女'],
  assetStateColor: ['success', 'processing', 'magenta'],
  newClassState: 'is_show_news_state',
  productClassState: 'is_show_product_state',
  eventClassState: 'is_show_event_state',
  TThousand: 10000,
  employeeAuthShowColor: ['magenta', 'magenta', 'gold', 'green', 'purple'],
  classActive: ['🌟', '🌟🌟', '🌟🌟🌟', '🌟🌟🌟🌟', '🌟🌟🌟🌟🌟'],
  userNewsState: ['关闭', '开启'],
  userNewsStateColor: ['error', 'success'],
}
