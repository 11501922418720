/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-19 17:25:16
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/apis/employee.ts
 */
import { request } from '../services/request'
import { authPath } from '../services/config'

export default class EmployeeRequest {
  // 添加员工
  static post_employee(data: any) {
    return request(authPath + 'auth/employee', 'POST', data, { throwErr: true })
  }
  // 更新员工信息
  static put_employee(id: number, data: any) {
    return request(authPath + `auth/employee/${id}`, 'PUT', data, { throwErr: true })
  }

  // 获取员工信息
  static get_employee(id: any) {
    return request(authPath + `auth/employee/${id}`, 'GET', {})
  }

  // 获取权限列表
  static get_auth_list() {
    return request(authPath + 'auth/auth_list', 'GET', {})
  }

  // 上传员工头像
  static post_profile_photo(data: any) {
    return request(authPath + 'auth/profile_photo/staf', 'POST', data)
  }

  // 修改员工信息
  static update_profile_info(id: number, data: any) {
    return request(authPath + `auth/employee_profile/${id}`, 'PUT', data, { throwErr: true })
  }

  // 获取活动列表
  static get_employee_list(page: number, count: number, query = {}) {
    return request(authPath + `auth/employees?query=${JSON.stringify(query)}&page=${page}&count=${count}`, 'GET', {}, { showloading: true })
  }
}
