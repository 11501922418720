/*
 * @Author: weiqi
 * @Date: 2021-10-12 11:18:31
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 14:31:33
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/utils/objectUtils.ts
 */
import _ from 'lodash'

class ObjectUtils {
  /**
   * 过滤对象属性
   * @param obj 传入对象
   * @param args 需要过滤的key
   * @param exclude 是否使用排除策略
   * @returns
   */
  static objectFilter(obj: any, args: any, exclude?: boolean) {
    return _.reduce(
      args,
      (result: any, value) => {
        if (!exclude) {
          result[value] = obj[value]
        } else {
          delete obj[value]
          result = obj
        }
        return result
      },
      {}
    )
  }

  /**
   * 替换Key
   * @param obj 传入对象
   * @param strategy 替换策略
   * @returns
   */
  static replaceKey(obj: any, strategy: any) {
    const replaceRes: any = {}
    _.each(strategy, (v: string, k: any) => {
      replaceRes[v] = obj[k]
    })
    return replaceRes
  }
}

export default ObjectUtils
