/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-16 22:48:08
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/apis/abstract.ts
 */
import { request } from '../services/request'
import { authPath } from '../services/config'

export default class ProductAbstractRequest {
  // 添加简介
  static post_product_abstract(data: any) {
    return request(authPath + 'auth/product_abstract', 'POST', data, { throwErr: true })
  }

  // 获取简介列表
  static get_product_abstract(productId: number) {
    return request(authPath + 'auth/product_abstract' + '/' + productId, 'GET', {})
  }

  // 删除
  static delete_product_abstract(product_abstract_id: number) {
    return request(authPath + 'auth/product_abstract' + '/' + product_abstract_id, 'DELETE', {}, { throwErr: true })
  }

  // 更新
  static put_product_abstract(product_abstract_id: number, data: any) {
    return request(authPath + 'auth/product_abstract' + '/' + product_abstract_id, 'PUT', data, { throwErr: true })
  }
}
