/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 13:26:19
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/apis/customer.ts
 */
import { request } from '../services/request'
import { authPath } from '../services/config'

export default class CustomerRequest {
  // 更新客户信息
  static put_customer(id: any, data: any) {
    return request(authPath + `auth/user/${id}`, 'PUT', data)
  }

  // 更新客户信息
  static put_customer_profile(id: any, data: any) {
    return request(authPath + `auth/userprofile/${id}`, 'PUT', data)
  }

  // 获取客户详情
  static get_customer_detail(id: any) {
    return request(authPath + `auth/user/${id}`, 'GET', {})
  }

  // 获取客户列表
  static get_customer_list(page: number, count: number, query = {}, sort?: any) {
    sort = sort ? JSON.stringify(sort) : ''
    return request(authPath + `auth/users?query=${JSON.stringify(query)}&page=${page}&count=${count}&sort=${sort}`, 'GET', {}, { showloading: true })
  }

  // 获取个人名下客户列表
  static request_manager_owners_customers(page: number, count: number, query = {}) {
    return request(authPath + `auth/user_profile?query=${JSON.stringify(query)}&page=${page}&count=${count}`, 'GET', {})
  }
}
