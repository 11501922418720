/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 23:56:13
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/news/newsListStore.ts
 */
import { observable, action, makeObservable } from 'mobx'
import { MessageUtils } from '../../utils'
import Request from '../../apis/news'
import { Utils } from '../../utils'
export class NewsListStore {
  news_list: any = {}
  total = 0
  constructor() {
    makeObservable(this, {
      news_list: observable,
      get_news_list: action,
    })
  }

  async del_news(id: string) {
    MessageUtils.MsgSuccess((await Request.del_news(id)).data.message)
  }

  // 获取资讯列表
  async get_news_list(page: number, count: number, query = {}) {
    this.news_list = (await Request.get_news_list(page, count, query)).data.data
  }

  pagination(page: number, count: number, query = {}) {
    const formatedQuery = { ...query }
    Utils.FormatFormData(formatedQuery)('news_title')('createdAt')('state')
    Utils.FormatRequestQuery(formatedQuery)('news_title', 'like')('createdAt', 'between')('state', 'eq')
    this.get_news_list(page, count, formatedQuery)
  }
}

export default new NewsListStore()
