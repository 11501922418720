import { makeObservable } from 'mobx'
import Request from '../../apis/message'
import { MessageUtils } from '../../utils'

export class SynchronizeStore {
  constructor() {
    makeObservable(this, {})
  }
  // 内容发布通知
  post_content_publish_announcement() {
    return new Promise(resolve => {
      Request.post_content_publish_announcement().then((result: { data: { message: string; data: any } }) => {
        resolve(true)
        MessageUtils.MsgSuccess(`${result.data.message}:成功${result.data.data.data.success}条，失败${result.data.data.data.fail}条`)
      })
    })
  }
}

export default new SynchronizeStore()
