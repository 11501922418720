/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 14:39:57
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/menu/menuStore.ts
 */
import { makeObservable, observable } from 'mobx'
import MenuRequst from '../../apis/menu'
export class MenuStore {
  menu_list: any = []
  constructor() {
    makeObservable(this, {
      menu_list: observable,
    })
  }

  // 获取菜单
  async get_menus() {
    const MenusRes = (await MenuRequst.get_menu()).data.data
    this.menu_list = MenusRes
    return { state: true, opt: MenusRes }
  }
}

export default new MenuStore()
