/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 14:02:31
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/apis/event.ts
 */
import { request } from '../services/request'
import { authPath } from '../services/config'

export default class EventRequest {
  // 添加活动
  static post_event(data: any) {
    return request(authPath + 'auth/event', 'POST', data, { showloading: true })
  }
  // 更新活动
  static put_event(id: any, data: any) {
    return request(authPath + `auth/event/${id}`, 'PUT', data, { showloading: true })
  }
  // 更新活动发布状态
  static put_event_publish(id: any, data: any) {
    return request(authPath + `auth/event/publish/${id}`, 'PUT', data, { showloading: true })
  }
  //添加活动封面图片
  static post_event_cover_image(data: any) {
    return request(authPath + 'auth/event/cover_upload', 'POST', data)
  }
  //添加活动内容图片
  static post_event_content_image(data: any) {
    return request(authPath + 'auth/content/event_upload', 'POST', data)
  }
  // 获取活动列表
  static get_events(page: number, count: number, query = {}) {
    return request(authPath + `auth/events?query=${JSON.stringify(query)}&page=${page}&count=${count}`, 'GET', {}, { showloading: true })
  }
  //删除活动
  static del_event(id: any) {
    return request(authPath + `auth/event/${id}`, 'DELETE', {})
  }
  //获取活动详情
  static get_event_detail(id: any) {
    return request(authPath + `auth/event/${id}`, 'GET', {}, { showloading: true })
  }
}
