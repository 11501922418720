/*
 * @Author: weiqi
 * @Date: 2021-11-17 21:15:36
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 14:38:57
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/banner/bannerListStore.ts
 */
// import _ from 'lodash'
import { observable, makeObservable } from 'mobx'
import moment from 'moment'

export class BannerListStore {
  banner_list: any = { rows: [], count: '' }

  constructor() {
    makeObservable(this, {
      banner_list: observable,
    })
  }

  pagination(page: number, count: number, query?: any) {
    const formatedQuery = { ...query }

    //设置query字段查询模式
    if (formatedQuery && formatedQuery.name)
      formatedQuery.name = {
        value: formatedQuery.name,
        type: 'like',
      }
    if (formatedQuery && formatedQuery.nick_name)
      formatedQuery.nick_name = {
        value: formatedQuery.nick_name,
        type: 'like',
      }
    if (formatedQuery && formatedQuery.createdAt)
      formatedQuery.createdAt = {
        value: [formatedQuery.createdAt, moment(formatedQuery.createdAt).add(1, 'year')],
        type: 'between',
      }
  }
}

export default new BannerListStore()
