/*
 * @Author: weiqi
 * @Date: 2021-09-18 10:23:30
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 11:26:03
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/utils/messageUtils.ts
 */
import { message } from 'antd'

class MessageUtils {
  static MsgSuccess(msg: string) {
    return message.success(msg)
  }

  static MsgWarning(msg: string) {
    return message.warn(msg)
  }
}

export default MessageUtils
