/*
 * @Author: weiqi
 * @Date: 2022-03-16 12:27:34
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 14:44:33
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/pages/UserCenter/menuItem/profile.tsx
 */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Avatar, Button, Divider, Row, Col, Descriptions, Tag, Skeleton } from 'antd'
import { useStore } from '../../../hooks/useStore'
import { observer } from 'mobx-react-lite'
import { EditUserProfile } from '../edit_profile'
import { base } from '../../../config/index'
// import _ from 'lodash'

export const Profile: React.FC<any> = observer(() => {
  const [editProfileVisible, setEditProfileVisible] = useState<boolean>(false)
  const { profileInstance } = useStore()
  const { username, nickname, avatar_url, phone, wechat_id, gender, email, is_show, sys_auth_type, employee_id } = profileInstance.get_profile()
  useEffect(() => {
    profileInstance.request_userprofile()
  }, [])

  const renderContent = () => {
    switch (employee_id) {
      case 0:
        return <Skeleton active />
      default:
        return (
          <>
            <Row>
              <Col span={5}>
                <Row gutter={[24, 18]} style={{ textAlign: 'center' }} justify='center'>
                  <Col span={24}>
                    <Avatar size={100} src={avatar_url} />
                  </Col>
                  <Col span={24}>
                    <Tag color='cyan' style={{ marginRight: 0 }}>
                      {sys_auth_type}
                    </Tag>
                  </Col>
                  <Col span={24}>
                    <Button
                      onClick={() => {
                        setEditProfileVisible(true)
                      }}
                      type='ghost'
                      size='middle'
                    >
                      编辑
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={18} offset={1}>
                <Descriptions title={username}>
                  <Descriptions.Item label='姓名'>{nickname}</Descriptions.Item>
                  <Descriptions.Item label='性别'>
                    <Tag color='blue'>{base.gender[gender]}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label='微信号'>{wechat_id}</Descriptions.Item>
                  <Descriptions.Item label='手机号码'>{phone}</Descriptions.Item>
                  <Descriptions.Item label='邮箱'>{email}</Descriptions.Item>
                  <Descriptions.Item label='展示名片'>
                    {' '}
                    <Tag color={`${base.employeeIsShowColor[is_show]}`}>{base.employeeIsShow[is_show]}</Tag>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Divider />
          </>
        )
    }
  }

  return (
    <>
      {renderContent()}
      <EditUserProfile editProfileVisible={editProfileVisible} setEditProfileVisible={setEditProfileVisible} />
    </>
  )
})
