/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 11:31:01
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/system/sysStore.ts
 */
import { makeObservable, observable, toJS } from 'mobx'
import { MessageUtils } from '../../utils'
import Request from '../../apis/system'

export class SystemCronjobsStore {
  sys_cronjobs: any = []
  constructor() {
    makeObservable(this, {
      sys_cronjobs: observable,
    })
  }

  // 获取系统任务
  async request_sys_cronjobs() {
    this.sys_cronjobs = (await Request.get_sys_cronjobs()).data.data
  }

  // 更新系统任务状态
  async put_sys_cronjob_state(data: any) {
    MessageUtils.MsgSuccess((await Request.put_sys_cronjob_state(data)).data.message)
  }

  get_sys_cronjobs() {
    return toJS(this.sys_cronjobs)
  }
}
