/*
 * @Author: weiqi
 * @Date: 2021-11-20 13:40:13
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-18 01:48:23
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/services/request.ts
 */
import axios, { AxiosResponse, Method } from 'axios'
import rootStore from '../stores/index'
import { loginStore } from '../stores/login'
import { message } from 'antd'
import { responseBody, requestOptions } from '../interfaces/network'
const defaultRequestOption: requestOptions = {
  showloading: false,
  throwErr: false,
  showMessage: true,
}

export function request(url: string, method: Method, data: any, options?: requestOptions): Promise<AxiosResponse<responseBody>> {
  const { showloading, throwErr, showMessage } = { ...defaultRequestOption, ...options }

  return new Promise((resolve, reject) => {
    const body = method === 'GET' ? 'params' : 'data'
    if (showloading) rootStore.loadingStore.set_loading()
    axios({
      url,
      method: method,
      [body]: data === null ? '' : data,
      headers: {
        'Content-Type': 'application/json',
        sessionid: loginStore.login_result ? loginStore.login_result.token : '',
      },
    })
      .then((res: AxiosResponse<responseBody>) => {
        if (res.status === 200 && res.statusText === 'OK' && res.data.statusCode === 200) {
          rootStore.loadingStore.remove_loading()
          resolve(res)
        } else {
          rootStore.loadingStore.remove_loading()
          throw res.data
        }
      })
      .catch((err: any) => {
        console.log('err', err)
        if (showMessage) message.warn(err.message, 2.5)
        rootStore.loadingStore.remove_loading()
        if ([30002, 30003].indexOf(err.statusCode) != -1) {
          window.location.href = '/'
        }
        if (throwErr) reject(err)
      })
  })
}
