/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 11:23:10
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/product/abstract.ts
 */
import { observable, makeObservable } from 'mobx'
import Request from '../../apis/abstract'
import { MessageUtils } from '../../utils'

export class ProductAbstractStore {
  product_abstract: any = {}
  constructor() {
    makeObservable(this, {
      product_abstract: observable,
    })
  }

  // 获取产品简介列表
  async get_product_abstract_list(productId: number) {
    this.product_abstract = (await Request.get_product_abstract(productId)).data.data
  }

  // 添加产品简介
  async add_product_abstract(data: any) {
    MessageUtils.MsgSuccess((await Request.post_product_abstract(data)).data.message)
  }

  // 更新产品简介
  async update_product_abstract(product_abstract_id: number, data: any) {
    MessageUtils.MsgSuccess((await Request.put_product_abstract(product_abstract_id, data)).data.message)
  }

  // 删除产品简介
  async del_product_abstract(product_abstract_id: number) {
    MessageUtils.MsgSuccess((await Request.delete_product_abstract(product_abstract_id)).data.message)
  }
}

export default new ProductAbstractStore()
