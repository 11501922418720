/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 13:48:18
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/assets/assetStore.ts
 */
import { action, makeObservable } from 'mobx'
import { MessageUtils } from '../../utils'
import Request from '../../apis/assets'

export class AssetStore {
  constructor() {
    makeObservable(this, {
      add_new_asset: action,
      update_asset: action,
    })
  }

  // 添加产品
  async add_new_asset(data: any) {
    MessageUtils.MsgSuccess((await Request.post_asset(data)).data.message)
  }

  // 获取资产详情
  async get_asset_detail(id: any) {
    return (await Request.get_asset_detail(id)).data.data
  }

  // 客户资产统计
  async get_asset_user_count(id: any) {
    return (await Request.get_asset_user_count(id)).data.data
  }

  // 更新资产详情
  async update_asset(id: any, data: any) {
    MessageUtils.MsgSuccess((await Request.put_asset(id, data)).data.message)
  }
}

export default new AssetStore()
