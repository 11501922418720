/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-07-03 17:17:57
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/apis/assets.ts
 */
import { request } from '../services/request'
import { authPath } from '../services/config'

export default class AssetsRequest {
  // 导入excel文件
  static upload_file(data: any) {
    return request(authPath + 'auth/import_asset', 'POST', data, { throwErr: true })
  }
  // 添加资产
  static post_asset(data: any) {
    return request(authPath + 'auth/asset', 'POST', data, { throwErr: true })
  }
  // 更新资产信息
  static put_asset(id: any, data: any) {
    return request(authPath + `auth/asset/${id}`, 'PUT', data, { throwErr: true })
  }

  // 获取资产明细列表
  static get_assets(page: number, count: number, query = {}) {
    return request(authPath + `auth/assets?query=${JSON.stringify(query)}&page=${page}&count=${count}`, 'GET', {}, { showloading: true })
  }

  // 获取资产明细列表（含产品）
  static get_assets_product(page: number, count: number, query = {}) {
    return request(authPath + `auth/assets_product?query=${JSON.stringify(query)}&page=${page}&count=${count}`, 'GET', {}, { showloading: true })
  }

  //删除资产记录
  static del_asset(id: any) {
    return request(authPath + `auth/asset/${id}`, 'DELETE', {})
  }
  //清空产品资产明细表
  static empty_assets(id: number) {
    return request(authPath + `auth/asset/empty/${id}`, 'DELETE', {}, { throwErr: true })
  }
  //获取资产详情
  static get_asset_detail(id: any) {
    return request(authPath + `auth/asset/${id}`, 'GET', {})
  }
  //客户资产统计
  static get_asset_user_count(id: any) {
    return request(authPath + `auth/asset/user/${id}`, 'GET', {})
  }
}
