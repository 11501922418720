/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 13:38:55
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/customer/customerStore.ts
 */
import { action, makeObservable } from 'mobx'
import { MessageUtils } from '../../utils'
import Request from '../../apis/customer'

export class CustomerStore {
  constructor() {
    makeObservable(this, {
      update_customer: action,
    })
  }

  // 获取客户详情
  async get_customer_detail(id: any) {
    return (await Request.get_customer_detail(id)).data.data
  }

  // 更新客户信息
  async update_customer(id: any, data: any) {
    MessageUtils.MsgSuccess((await Request.put_customer(id, data)).data.message)
  }

  // 更新客户基本信息
  async update_customer_profile(id: any, data: any) {
    MessageUtils.MsgSuccess((await Request.put_customer_profile(id, data)).data.message)
  }
}

export default new CustomerStore()
