/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 14:04:01
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/event/eventStore.ts
 */
import { action, makeObservable, observable } from 'mobx'
import { MessageUtils } from '../../utils'
import Request from '../../apis/event'
export class EventStore {
  event_detail: any = {}
  constructor() {
    makeObservable(this, {
      add_new_event: action,
      update_event: action,
      add_event_cover_image: action,
      event_detail: observable,
    })
  }

  // 添加产品
  async add_new_event(data: any) {
    MessageUtils.MsgSuccess((await Request.post_event(data)).data.message)
  }

  // 添加产品广告图片
  async add_event_cover_image(data: any) {
    const postCoverImgRes = await Request.post_event_cover_image(data)
    MessageUtils.MsgSuccess(postCoverImgRes.data.message)
    return { opt: postCoverImgRes.data, state: true }
  }

  // 添加内容图片
  async upload_content_image(data: any) {
    const postContentImageRes = await Request.post_event_content_image(data)
    MessageUtils.MsgSuccess(postContentImageRes.data.message)
    return { opt: postContentImageRes.data, state: true }
  }

  // 获取活动详情
  async get_event_detail(id: any) {
    const EventDetail = (await Request.get_event_detail(id)).data.data
    this.event_detail = EventDetail
    return EventDetail
  }

  // 更新活动详情
  async update_event(id: any, data: any) {
    MessageUtils.MsgSuccess((await Request.put_event(id, data)).data.message)
  }

  // 更新活动发布状态
  async update_event_publish(id: any, data: any) {
    MessageUtils.MsgSuccess((await Request.put_event_publish(id, data)).data.message)
  }

  //根据开始/结束时间对比当前时间，显示活动状态
  event_state(start: any, end: any): string {
    if (!(start && end)) return ''
    const current: any = new Date()
    if (start - current > 0) {
      return '未开始'
    } else if (end - current <= 0) {
      return '已结束'
    }
    return '进行中'
  }
}

export default new EventStore()
