/*
 * @Author: weiqi
 * @Date: 2021-12-30 23:46:03
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-18 01:37:11
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/pages/Login/index.tsx
 */
import { Layout, Input, Button, Divider, Form } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { ILoginType } from '../../interfaces/view/login'
import { useStore } from '../../hooks/useStore'
import '../../style/view-style/login.scss'
import { useState } from 'react'

const Login = (props: any) => {
  const { loginStore } = useStore()
  const [loading, setLoading] = useState<boolean>(false)

  // 登录
  const handleSubmit = async (e: ILoginType) => {
    try {
      setLoading(true)
      await loginStore.login(e)
      props.history.push('/home')
      setLoading(false)
    } catch (e: any) {
      setLoading(false)
    }
  }

  return (
    <Layout className='login animated fadeIn'>
      <div className='model'>
        <div className='login-form'>
          <h3 style={{ textAlign: 'center' }}><内容及资源管理平台/h3>
          <Divider />
          <Form onFinish={handleSubmit}>
            <Form.Item name='username'>
              <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='请输入用户名' />
            </Form.Item>
            <Form.Item name='password'>
              <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type='password' placeholder='请输入密码' />
            </Form.Item>
            <Form.Item>
              <Button block type='primary' htmlType='submit' loading={loading}>
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <a
        style={{
          textDecoration: 'none',
          textAlign: 'center',
          color: '#86909c',
        }}
        target='_blank'
        href='https://beian.miit.gov.cn/#/Integrated/recordQuery'
        rel='noreferrer'
      >
        浙ICP备2020045059号-2
      </a>
    </Layout>
  )
}

export default Login
