/*
 * @Author: weiqi
 * @Date: 2022-03-14 15:14:23
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-16 22:26:19
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/apis/statistics.ts
 */
import { request } from '../services/request'
import { authPath } from '../services/config'

export class ProductStatisticRequest {
  // 获取产品募资数据统计
  static request_product_statistics(product_id: number, query = {}) {
    return request(authPath + `auth/product_statistics/${product_id}?&query=${JSON.stringify(query)}`, 'GET', {})
  }
  /**
   * 获取产品统计数据总览
   * @param product_id
   * @returns
   */
  static request_statistics_overview(product_id: number) {
    return request(authPath + `auth/product_funding_statistics/${product_id}`, 'GET', {}, { showloading: true })
  }
  //获取员工募资进度详情
  static request_employee_work_statistics(product_id: number, created_by: number, query = {}) {
    return request(authPath + `auth/product_statistics/${product_id}/${created_by}?&query=${JSON.stringify(query)}`, 'GET', {})
  }
  /**
   * 获取产品募资趋势图数据
   * @param product_id
   * @returns
   */
  static request_product_progress_trend(product_id: number) {
    return request(authPath + `auth/product_statistics_chart/${product_id}`, 'GET', {}, { showloading: true })
  }
}
