/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 14:30:49
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/message/custromizeStore.ts
 */
import { makeObservable, observable } from 'mobx'
import { MessageUtils } from '../../utils'
import Request from '../../apis/system'

export class CustomizeCronjobsStore {
  custromize_crojobs: any
  constructor() {
    makeObservable(this, {
      custromize_crojobs: observable,
    })
  }

  // 获取自定义任务
  async request_custromize_crojobs() {
    this.custromize_crojobs = (await Request.get_customize_cronjobs()).data.data
  }

  // 更新自定义任务状态
  async put_custromize_crojob_state(data: any) {
    MessageUtils.MsgSuccess((await Request.put_customize_cronjob_state(data)).data.message)
  }
}

export default new CustomizeCronjobsStore()
