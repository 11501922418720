/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 14:13:26
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/apis/product.ts
 */
import { request } from '../services/request'
import { authPath } from '../services/config'

export default class ProductRequest {
  // 添加产品
  static post_product(data: any) {
    return request(authPath + 'auth/product', 'POST', data, { showloading: true })
  }
  // 添加产品内容图片
  static post_product_photo(data: any) {
    return request(authPath + 'auth/product_details', 'POST', data)
  }
  // 获取产品列表
  static get_product(page: number, count: number, query = {}) {
    return request(authPath + `auth/product?query=${JSON.stringify(query)}&page=${page}&count=${count}`, 'GET', {}, { showloading: true })
  }
  // 删除产品
  static delete_product(product_id: number) {
    return request(authPath + 'auth/product' + '/' + product_id, 'DELETE', {})
  }
  // 获取产品详情
  static request_product_detail(product_id: number) {
    return request(authPath + 'auth/product' + '/' + product_id, 'GET', {}, { showloading: true })
  }
  // 更新产品
  static put_product(product_id: number, data: any) {
    return request(authPath + 'auth/product' + '/' + product_id, 'PUT', data, { showloading: true })
  }

  // 更新产品发布状态
  static put_product_publish(product_id: number, data: any) {
    return request(authPath + 'auth/product/publish' + '/' + product_id, 'PUT', data)
  }

  // 获取产品募资进度图表:deprecated
  static get_product_charts(product_id: number, query: any) {
    query = query ? JSON.stringify(query) : ''
    return request(authPath + 'auth/product_charts' + '/' + product_id + '?query=' + query, 'GET', {}, { showloading: true })
  }
}
