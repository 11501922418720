/*
 * @Author: weiqi
 * @Date: 2022-03-16 11:18:27
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 14:14:42
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/index.ts
 */
import { loadingStore, LoadingStore } from './loading'
import { loginStore, LoginStore } from './login'
import { assetListStoreInstance, AssetListStore, AssetStore, assetStoreInstance } from './assets'
import productStores, { ProductStores } from './product/index'
import { eventStoreInstance, EventStore, eventListStoreInstance, EventListStore } from './event'
import { newsStoreInstance, NewsStore, newsListStoreInstance, NewsListStore } from './news'
import productAbstractStore, { ProductAbstractStore } from './product/abstract'
import { ProductStatisticsStore } from './product/statistics'
import { EmployeeListStore, EmployeeStore, employeeListStoreInstance, employeeStoreInstance } from './employee'
import { CustomerListStore, CustomerStore, customerListStoreInstance, customerStoreInstance } from './customer'
import { ProfileStore } from './profile'
import { homeStore, HomeStore } from './home'
import { menuStore, MenuStore } from './menu'
import { CompanyListStore, CompanyStore, companyListStoreInstance, companyStoreInstance } from './company'
import { BannerListStore, BannerStore, bannerListStoreInstance, bannerStoreInstance } from './banner'
import { SystemCronjobsStore } from './system'
import { customizeCronjobsStore, CustomizeCronjobsStore, synchronizeStore, SynchronizeStore } from './message'

export type RootStore = {
  loadingStore: LoadingStore
  loginStore: LoginStore
  assetListStoreInstance: AssetListStore
  assetStoreInstance: AssetStore
  productStores: ProductStores
  eventStoreInstance: EventStore
  eventListStoreInstance: EventListStore
  newsStoreInstance: NewsStore
  newsListStoreInstance: NewsListStore
  productAbstractStore: ProductAbstractStore
  productStatisticsInstance: ProductStatisticsStore
  employeeStoreInstance: EmployeeStore
  employeeListStoreInstance: EmployeeListStore
  customerStoreInstance: CustomerStore
  customerListStoreInstance: CustomerListStore
  profileInstance: ProfileStore
  homeStore: HomeStore
  menuStore: MenuStore
  companyStoreInstance: CompanyStore
  companyListStoreInstance: CompanyListStore
  bannerStoreInstance: BannerStore
  bannerListStoreInstance: BannerListStore
  customizeCronjobsStore: CustomizeCronjobsStore
  systemCronjobsStore: SystemCronjobsStore
  synchronizeStore: SynchronizeStore
}

const rootStore = {
  loadingStore,
  loginStore,
  assetListStoreInstance,
  assetStoreInstance,
  productStores,
  eventStoreInstance,
  eventListStoreInstance,
  newsStoreInstance,
  newsListStoreInstance,
  productAbstractStore,
  productStatisticsInstance: new ProductStatisticsStore(),
  employeeStoreInstance,
  employeeListStoreInstance,
  customerStoreInstance,
  customerListStoreInstance,
  profileInstance: new ProfileStore(),
  homeStore,
  menuStore,
  companyStoreInstance,
  companyListStoreInstance,
  bannerStoreInstance,
  bannerListStoreInstance,
  customizeCronjobsStore,
  systemCronjobsStore: new SystemCronjobsStore(),
  synchronizeStore,
}

export default rootStore
