/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-16 16:49:06
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/apis/login.ts
 */
import { request } from '../services/request'
import { authPath } from '../services/config'

export default class ConnectRequest {
  // 登陆
  static login(data: any) {
    return request(authPath + 'login', 'POST', data, { throwErr: true })
  }
  // 获取可访问类
  static getAuthClass() {
    return request(authPath + 'auth/permitted_option_ctrl', 'GET', {})
  }

  //登出
  static logout() {
    return request(authPath + 'logout', 'POST', {})
  }
}
