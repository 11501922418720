/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 13:45:26
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/employee/empolyeeListStore.ts
 */
import { observable, action, makeObservable, toJS } from 'mobx'
import { EmployeeListType } from '../../interfaces/business'
import Request from '../../apis/employee'
export class EmployeeListStore {
  employee_list: EmployeeListType = { count: 0, rows: [] }
  auth_list: any = []
  total = 0

  constructor() {
    makeObservable(this, {
      employee_list: observable,
      auth_list: observable,
      request_employee_list: action,
    })
  }

  // 获取员工列表
  async request_employee_list(page: number, count: number, query = {}) {
    this.employee_list = (await Request.get_employee_list(page, count, query)).data.data
  }

  // 获取权限列表
  async get_auth_list() {
    this.auth_list = (await Request.get_auth_list()).data.data
  }

  pagination(page: number, count: number, query?: any) {
    const formatedQuery = { ...query }
    // Utils.FormatFormData(formatedQuery)('news_title')

    //设置query字段查询模式
    // if (formatedQuery && formatedQuery.news_title)
    //   formatedQuery.news_title = {
    //     value: formatedQuery.news_title,
    //     type: 'like',
    //   }
    // if (formatedQuery && formatedQuery.createdAt)
    //   formatedQuery.createdAt = {
    //     value: [formatedQuery.createdAt, moment(formatedQuery.createdAt).add(1, 'year')],
    //     type: 'between',
    //   }
    this.request_employee_list(page, count, formatedQuery)
  }

  get_employee_list() {
    return toJS(this.employee_list)
  }
}

export default new EmployeeListStore()
