/*
 * @Author: weiqi
 * @Date: 2021-08-22 21:29:46
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 11:30:52
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/utils/excel.ts
 */
import _ from 'lodash'
import XLSX from 'xlsx'

class Excel {
  private sheet2blob(json: any) {
    const workbook: XLSX.WorkBook = {
      SheetNames: Object.keys(json),
      Sheets: {},
    }

    _.each(json, (sheet, sheetName) => {
      const thisSheet = XLSX.utils.json_to_sheet(sheet)
      workbook.Sheets[sheetName] = thisSheet
    })

    const wopts: XLSX.WritingOptions = {
      bookType: 'xlsx',
      bookSST: false,
      type: 'binary',
    }

    const wbout = XLSX.write(workbook, wopts)
    const bolb = new Blob([s2ab(wbout)], {
      type: 'application/octet-stream',
    })

    function s2ab(s: any) {
      const buf = new ArrayBuffer(s.length)
      const view = new Uint8Array(buf)
      for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
      return buf
    }

    return bolb
  }

  private openDownloadDialog(url: any, saveName: string) {
    if (typeof url == 'object' && url instanceof Blob) {
      // 创建blob地址
      url = URL.createObjectURL(url)
    }
    const aLink = document.createElement('a')
    aLink.href = url
    aLink.download = saveName || ''
    let event: any
    if (window.MouseEvent) event = new MouseEvent('click')
    else {
      event = document.createEvent('MouseEvent')
      event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    }
    aLink.dispatchEvent(event)
  }

  DownloadExcel(json: any) {
    this.openDownloadDialog(this.sheet2blob(json), `${new Date().getTime()}.xlsx`)
  }
}

export default new Excel()
