/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-18 01:03:05
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/product/index.ts
 */
import { observable, action, makeObservable, toJS } from 'mobx'
import { MessageUtils } from '../../utils'
import { Utils } from '../../utils'
import Request from '../../apis/product'
import { ProductListType } from '../../interfaces/business'

export class ProductStores {
  product_list: ProductListType = { count: 0, rows: [] }
  product_detail: any = {}
  constructor() {
    makeObservable(this, {
      product_list: observable,
      product_detail: observable,
      add_product_data: action,
    })
  }

  // 添加产品
  async add_product_data(data: any) {
    MessageUtils.MsgSuccess((await Request.post_product(data)).data.message)
  }

  // 添加产品广告图片
  async add_product_photo_data(data: any) {
    const postPhoteRes = (await Request.post_product_photo(data)).data
    return { opt: postPhoteRes, state: true }
  }

  // 获取产品列表
  async request_product_list(page: number, count: number, query?: any) {
    this.product_list = (await Request.get_product(page, count, query)).data.data
  }

  pagination(page: number, count: number, query?: any) {
    const formatedQuery = { ...query }
    Utils.FormatFormData(formatedQuery)('product_title')('start_at')('state')
    Utils.FormatRequestQuery(formatedQuery)('product_title', 'like')('start_at', 'between')('state', 'eq')

    this.request_product_list(page, count, formatedQuery)
  }

  // 获取产品详情
  async request_product_detail(product_id: number) {
    this.product_detail = (await Request.request_product_detail(product_id)).data.data
  }

  // 删除产品
  async del_product(product_id: number) {
    MessageUtils.MsgSuccess((await Request.delete_product(product_id)).data.message)
  }

  // 更新产品
  async update_product(product_id: number, data: any) {
    MessageUtils.MsgSuccess((await Request.put_product(product_id, data)).data.message)
  }

  // 更新产品发布状态
  async update_product_publish(product_id: number, data: any) {
    MessageUtils.MsgSuccess((await Request.put_product_publish(product_id, data)).data.message)
  }

  get_product_list() {
    return toJS(this.product_list)
  }
}

export default new ProductStores()
