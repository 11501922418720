/*
 * @Author: weiqi
 * @Date: 2021-11-19 11:06:32
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 14:45:59
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/index.tsx
 */
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import zh_CN from 'antd/lib/locale/zh_CN'
import { ConfigProvider } from 'antd'
import { StoreProvider } from './hooks/useStore'
// import { processErrorInfo } from './utils'

import App from './app'

ReactDOM.render(
  <ConfigProvider locale={zh_CN}>
    <StoreProvider>
      <App />
    </StoreProvider>
  </ConfigProvider>,
  document.getElementById('root')
)

reportWebVitals()
