/*
 * @Author: weiqi
 * @Date: 2021-11-24 10:28:22
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 00:18:24
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/company/companyStore.ts
 */
import { action, makeObservable } from 'mobx'
import { MessageUtils } from '../../utils'
import Request from '../../apis/company'

export class CompanyStore {
  constructor() {
    makeObservable(this, {
      add_company: action,
      update_company: action,
    })
  }

  // 添加公司
  async add_company(data: any) {
    MessageUtils.MsgSuccess((await Request.post_company(data)).data.message)
  }

  // 获取公司详情
  async get_company_detail(id: any) {
    return (await Request.get_company_detail(id)).data.data
  }

  // 更新公司
  async update_company(id: any, data: any) {
    MessageUtils.MsgSuccess((await Request.put_company(id, data)).data.message)
  }
}

export default new CompanyStore()
