/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-21 01:18:54
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/stores/login/login.ts
 */
import { observable, action, makeObservable, toJS } from 'mobx'
import { ILoginType } from '../../interfaces/view/login'
import { MessageUtils } from '../../utils'
import { LoginPayloadType } from '../../interfaces/business'
import Request from '../../apis/login'
export class LoginStore {
  auth_class: any
  login_result: LoginPayloadType = {
    token: '',
    profile: {
      avatar_url: '',
      createdAt: '',
      employee_id: 0,
      is_active: 1,
      sys_auth_id: 3,
      updatedAt: '',
      username: '',
    },
  }
  constructor() {
    makeObservable(this, {
      login: action,
      login_result: observable,
      auth_class: observable,
    })
    // 登录状态持久化
    if (this.login_result.profile.employee_id === 0) {
      const data: any = localStorage.getItem('loginState')
      if (data) this.login_result = JSON.parse(data)
    }
    // 持久化可访问权限class
    if (this.auth_class == undefined) {
      const data: any = localStorage.getItem('authClass')
      if (data) this.auth_class = JSON.parse(data)
    }
  }

  // 登录
  async login(data: ILoginType) {
    const loginRes = (await Request.login(data)).data.data
    this.login_result = loginRes
    this.get_auth_class()
    localStorage.setItem('loginState', JSON.stringify(loginRes))
  }

  sync_login_profile(profile: LoginPayloadType['profile']) {
    this.login_result.profile = profile
    localStorage.setItem('loginState', JSON.stringify(this.login_result))
  }

  isAdmin() {
    const sys_auth_id = this.login_result.profile.sys_auth_id
    return sys_auth_id === 1 || sys_auth_id === 2
  }

  get_login_info() {
    return toJS(this.login_result)
  }

  // 获取可访问全Class权限
  async get_auth_class() {
    const authInfo = (await Request.getAuthClass()).data.data
    this.auth_class = authInfo
    localStorage.setItem('authClass', JSON.stringify(authInfo))
  }

  // 登出
  async logout() {
    MessageUtils.MsgSuccess((await Request.logout()).data.message)
  }
}

export default new LoginStore()
