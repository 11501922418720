/*
 * @Author: weiqi
 * @Date: 2021-11-18 12:43:59
 * @LastEditors: weiqi
 * @LastEditTime: 2022-03-17 09:58:13
 * @Description: file content
 * @FilePath: /BZYCRM/CMS/frontend/src/apis/banner.ts
 */
import { request } from '../services/request'
import { authPath } from '../services/config'

export default class BannerRequest {
  // 添加轮播图
  static post_banner(data: any) {
    return request(authPath + 'auth/banner', 'POST', data)
  }
  // 更新轮播图信息
  static put_banner(id: any, data: any) {
    return request(authPath + `auth/banner/${id}`, 'PUT', data)
  }

  // 删除轮播图信息
  static delete_banner(id: any, data: any) {
    return request(authPath + `auth/banner/${id}`, 'DELETE', data)
  }

  //获取轮播图详情
  static get_banner_detail(id: any) {
    return request(authPath + `auth/banner/${id}`, 'GET', {})
  }

  //添加轮播图图片
  static post_banner_cover_image(data: any) {
    return request(authPath + 'auth/banner_upload', 'POST', data)
  }
}
